import axios from 'axios';
import { queryCache } from 'react-query';
const localStorageKey = 'is_logged';

const okResponses = [
  'OK',
  'CREATED',
  200,
  201,
]; /* TODO add missing ones as we go */

/**
 * A function to handle api calls
 * @param endpoint
 * @param body
 * @param customConfig method for the api request
 * @returns {Promise<AxiosResponse<any>>}
 */
async function client(endpoint, { body, ...customConfig } = {}) {
  const headers = { 'content-type': 'application/json' };

  const API =
    customConfig?.api === 'measurement'
      ? process.env.REACT_APP_DATA_API_HOST
      : customConfig?.api === 'data'
      ? process.env.REACT_APP_DATA_API_HOST
      : process.env.REACT_APP_AUTH_API_HOST;

  /** This allows to pass in different http methods */
  customConfig['method'] = customConfig?.method
    ? customConfig.method
    : body
    ? 'post'
    : 'get';

  const config = {
    url: `${API}/${endpoint}`,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
  };

  if (body) {
    config.data = body;
  }

  return axios(config)
    .then((r) => {
      if (r.status === 401) {
        logout();
        // refresh the page for them
        window.location.assign('/login');
        return;
      }
      const data = r.data;
      if (okResponses.includes(r.status)) {
        return data;
      } else {
        return Promise.reject(data);
      }
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        //logout();
        // move to login screen
        //window.location('/login');
        handleLogoutResponse();
        window.location.assign('/login');
        return;
      }
      if (
        error.response &&
        error.response.data &&
        (error.response.data.error || error.response.data.errors)
      ) {
        return Promise.reject({
          message:
            error.response.data.error ||
            error.response.data.errors ||
            error.response.data,
        });
      } else if (
        /*this structure was received in case of 403 error for unauth access*/
        error.response &&
        error.response.status === 403 &&
        error.response.data &&
        error.response.data.detail
      ) {
        handleLogoutResponse();
        window.location.assign('/login');
        return Promise.reject({ message: error.response.data.detail });
      } else {
        return Promise.reject({ message: 'Something went wrong' });
      }
    });
}

function handleLogoutResponse() {
  queryCache.clear();
  window.localStorage.removeItem(localStorageKey);
}

function logout() {
  client('logout').then(handleLogoutResponse);
}

export { client, localStorageKey, logout, handleLogoutResponse };
