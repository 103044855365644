import { client, localStorageKey, handleLogoutResponse } from './apiClient';

/** Please don't remove the below function.
 * This is needed to move the user to registration success page after successful form submission
 * Without this user is navigated to dashboard page w/o authentication
 * and this breaks the application
 */
function handleRegisterResponse() {
  console.log('handleRegisterResponse');
}

function handleUserResponse({ credentials, status }) {
  window.localStorage.setItem(localStorageKey, true);

  return { credentials, status };
}

function handleForgotPwdResponse() {
  //clear previous login session saved tokens to help redirect from the email to change-password screen
  handleLogoutResponse();
}

function getUser() {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }

  return client('profile').then((data) => data);
}

function editProfile(data) {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }
  return client('profile', { body: data, method: 'PUT' }).then(
    handleUserResponse
  );
}

function editPassword(data) {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }
  return client('password', { body: data, method: 'PUT' }).then(
    handleUserResponse
  );
}

function getMe() {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }

  return client('api/1.0/accounts/me', { api: 'data' }).then((data) => data);
}

function login({ username, password }) {
  return client('auth', { body: { username, password } }).then(
    handleUserResponse
  );
}

function register({
  name,
  family_name,
  email,
  password,
  access_code,
  security_code,
}) {
  return client('register', {
    body: { name, family_name, email, password, access_code, security_code },
  }).then(handleRegisterResponse);
}

function forgotPassword({ username }) {
  return client('forgot_password', { body: { username } }).then(
    handleForgotPwdResponse
  );
}

function confirmForgotPassword({ username, password, code }) {
  return client('confirm_forgot_password', {
    body: { username, password, code },
  }).then(handleForgotPwdResponse);
}

function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

function isLoggedIn() {
  return Boolean(getToken());
}

export {
  login,
  register,
  forgotPassword,
  confirmForgotPassword,
  getToken,
  getUser,
  isLoggedIn,
  getMe,
  editProfile,
  editPassword,
};
export { logout } from './apiClient';
