import { useQuery, useMutation, queryCache } from 'react-query';
import { client } from './apiClient';

const defaultMutationOptions = {
  onError: (err, variables, recover) =>
    typeof recover === 'function' ? recover() : null,
  onSettled: () => queryCache.refetchQueries('housingCompanies'),
  useErrorBoundary: false,
  throwOnError: true,
};

function readCompanies() {
  return client('api/1.0/housings/housing-company', { api: 'data' })
    .then((data) => data)
    .catch(console.log);
}

function createCompany({
  company: name,
  address,
  postCode: post_code,
  city,
  country,
}) {
  return client('api/1.0/housings/housing-company', {
    body: { name, address, post_code, city, country },
    api: 'data',
  });
}

function useHousing({ onSuccess, ...options } = {}) {
  const { data: housingCompanies } = useQuery({
    queryKey: 'housingCompanies',
    queryFn: readCompanies,
    ...options,
  });

  return housingCompanies ?? [];
}

function useCreateHousing(options) {
  return useMutation(createCompany, {
    ...defaultMutationOptions,
    ...options,
  });
}

/* Following function allows housing managers to read tenants list*/
function useTenants(group, { onSuccess, ...options } = {}) {
  const { data: tenants } = useQuery(['tenants', group], async () => {
    const data = await readTenants(group);
    return data;
  });
  return tenants ?? [];
}

function readTenants(group) {
  const api_pkg = group === 'housing_manager' ? 'housing_manager' : 'accounts';
  return client(`api/1.0/${api_pkg}/tenant`, { api: 'data' })
    .then((data) => data)
    .catch((error) => error);
}

function getNextPageData(group, queryStr) {
  const api_pkg = group === 'housing_manager' ? 'housing_manager' : 'accounts';
  if (queryStr) {
    return new Promise((resolve, reject) => {
      client(`api/1.0/${api_pkg}/tenant?${queryStr}`, {
        api: 'data',
      })
        .then((data) => {
          resolve(data);
        })
        .catch(function(error) {
          reject();
        });
    });
  }
}

function generateQueryParams(opt) {
  let val = opt && opt.options === 'statuses' ? opt?.bool : opt?.value;
  return opt && Object.keys(opt).length !== 0 ? `${opt.key}=${val}` : null;
}

function useUpdateHousingCompanyDetails(data, id) {
  return client(`api/1.0/housings/housing-company/${id}`, {
    api: 'data',
    method: 'PUT',
    body: data,
  }).then(
    (res) => res,
    (error) => error
  );
}

function getHousingMapping(userGroup) {
  return client(`api/1.0/${userGroup}/housing-mapping`, {
    api: 'data',
  });
}

export {
  useHousing,
  useCreateHousing,
  useTenants,
  useUpdateHousingCompanyDetails,
  getNextPageData,
  getHousingMapping,
  generateQueryParams,
};
