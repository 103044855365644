import React from 'react';
import classNames from 'classnames';
import * as MaterialDesignIcons from 'react-icons/md';
import * as Typicons from 'react-icons/ti';
import * as FontAwesome from 'react-icons/fa';
import * as AntDesign from 'react-icons/ai';
import * as Hero from 'react-icons/hi';
import * as Box from 'react-icons/bi';
import * as Typ from 'react-icons/ti';
import * as IcoMoon from 'react-icons/im';
import * as Vsc from 'react-icons/vsc';
import ReactTooltip from 'react-tooltip';

/* TODO it's only rounded version, adapt to basic icon use also */
const Icon = ({
  name = 'MdFileDownload',
  iconProps = { size: '1.5rem', color: 'text-blue' },
  oval = false,
  className = '',
  handler,
  id,
  tooltip,
}) => {
  let Icon =
    MaterialDesignIcons[name] ||
    Typicons[name] ||
    FontAwesome[name] ||
    AntDesign[name] ||
    Hero[name] ||
    Box[name] ||
    Typ[name] ||
    IcoMoon[name] ||
    Vsc[name];

  const iconHandler = () => {
    if (typeof handler === 'function') {
      handler(id);
    }
  };
  return (
    <div
      className={classNames(className, oval ? 'icon-container' : '')}
      onClick={iconHandler}
      data-testid="icon"
    >
      <Icon
        size={iconProps.size}
        className={classNames('inline', iconProps.color)}
        data-for={tooltip ? id : null}
        data-tip
      />
      {tooltip ? (
        <ReactTooltip id={id} place="right" type="info" effect="solid">
          <span>{tooltip}</span>
        </ReactTooltip>
      ) : null}
    </div>
  );
};

export default Icon;
