import React from 'react';
import { bootstrapAppData } from '../utils/bootstrap';
import * as authClient from '../utils/authClient';
import * as tenantClient from '../utils/tenant';
import { useAsync } from '../utils/useAsync';
import { keys } from 'lodash';
import FullPageLoader, {
  FullPageErrorFallback,
} from '../elements/fullPageLoader/fullPageLoader';

/* Creates context for auth data and relative methods
 * at the moment it keep also keeps use contract data
 * that can be moved under utils, as is happening with weather.js util
 */

const AuthContext = React.createContext();
AuthContext.displayName = 'AuthContext';

const appDataPromise = bootstrapAppData();

function AuthProvider(props) {
  const {
    data,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync();

  /* App bootstrap data fetching */
  React.useLayoutEffect(() => {
    run(appDataPromise);
  }, [run]);

  /* login, register, logout handling, logic is in authClient */
  const login = React.useCallback(
    (form) => authClient.login(form).then((user) => run(bootstrapAppData())),
    [run]
  );
  const register = React.useCallback(
    (form) => authClient.register(form).then((user) => setData({ user })),
    [setData]
  );
  const forgotPassword = React.useCallback(
    (form) => authClient.forgotPassword(form).then((user) => setData({ user })),
    [setData]
  );
  const confirmForgotPassword = React.useCallback(
    (form) =>
      authClient.confirmForgotPassword(form).then((user) => setData({ user })),
    [setData]
  );
  const logout = React.useCallback(() => {
    authClient.logout();
    setData(null);
  }, [setData]);

  const user = data?.user;
  const me = data?.me;
  const housingMapping = data?.housingMapping;
  /* TODO Need to move handling of this under utils/tenant.js */
  const contract = data?.contract;
  /* updateUser method partially updates
   * the user profile and save it in context
   * email is mandatory to be sent to update profile due to Cognito
   * */
  const updateUser = React.useCallback(
    (dataToUpdate) => {
      let sendData = '';
      keys(dataToUpdate).length === 1 && keys(dataToUpdate)[0] === 'email'
        ? (sendData = { ...dataToUpdate })
        : (sendData = { ...dataToUpdate, email: user?.profile.email });
      return authClient.editProfile(sendData).then(
        () => {
          data['user']['profile'] = { ...data.user.profile, ...dataToUpdate };
          setData({ ...data });
        },
        (error) => error
      );
    },
    [setData, user, data]
  );

  /* updatePwd method exclusively updates old password
   * with the new one
   * */
  const updatePwd = React.useCallback(
    (dataToUpdate) => {
      return authClient.editPassword(dataToUpdate).then(
        () => {
          data['user']['profile'] = { ...data.user.profile, ...dataToUpdate };
          setData({ ...data });
        },
        (error) => error
      );
    },
    [setData, data]
  );

  /* updateSecurityDetails method exclusively updates security variables
   * such as energy data storage and electricity cost. When successful,
   * it then updates the context data key "me" which returns updated values
   * */
  const updateSecurityDetails = React.useCallback(
    (dataToUpdate) => {
      tenantClient.updateSecurity(dataToUpdate).then((res) => {
        data['me'] = { ...data.me, ...res };
        setData({ ...data });
      });
    },
    [setData, data]
  );

  const value = React.useMemo(
    () => ({
      user,
      login,
      logout,
      register,
      forgotPassword,
      confirmForgotPassword,
      me,
      contract,
      updateUser,
      updatePwd,
      updateSecurityDetails,
      housingMapping,
    }),
    [
      login,
      logout,
      register,
      forgotPassword,
      confirmForgotPassword,
      user,
      me,
      contract,
      updateUser,
      updatePwd,
      updateSecurityDetails,
      housingMapping,
    ]
  );

  if (isLoading || isIdle) {
    return <FullPageLoader />;
  }

  if (isError) {
    return <FullPageErrorFallback error={error} />;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
